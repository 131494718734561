var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('v-container',{staticClass:"pa-6"},[_c('div',{staticClass:"text-h5 font-weight-bold mb-4"},[_vm._v("All Pools")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"input",attrs:{"hide-details":"","outlined":"","flat":"","solo":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search by Pool name, Token address, Token sympol"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.vm.fetchPools(1, _vm.searchKey)}},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1)],1),_c('v-card',{staticClass:"mt-8",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"server-items-length":_vm.vm.totalCount,"footer-props":{ itemsPerPageOptions: [10] },"items":_vm.vm.allPools,"headers":_vm.headers,"loading":_vm.vm.loading},on:{"update:page":function($event){return _vm.vm.fetchPools($event)},"click:row":_vm.showDetail},scopedSlots:_vm._u([{key:"item.ratio",fn:function(ref){
var item = ref.item;
return [_c('div',[(!item.contract)?_c('div',[_vm._v(" TBA ")]):_c('div',[_vm._v("1 "+_vm._s(item.tradeToken)+" = "+_vm._s(item.token2TradeToken)+" "+_vm._s(item.pool.tokenName))])])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",class:{
              'progress-desktop': _vm.$vuetify.breakpoint.mdAndUp,
              'progress-mobile': _vm.$vuetify.breakpoint.smAndDown
            }},[_c('div',{staticStyle:{"white-space":"nowrap","width":"50px"}},[_vm._v(_vm._s(_vm._f("round")(item.progress,2))+"%")]),_c('v-progress-linear',{staticClass:"ml-2",attrs:{"height":"12","rounded":"","value":item.progress}})],1)]}},{key:"item.poolState",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('pool-state',{attrs:{"state":item.poolState}})],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex"})],1),_c('v-divider',{staticClass:"mt-7"}),_c('company-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }